const faqs = [
  {
    title: "Who we are & our policy",
    description:
      "By accessing and using our website or app, you agree to comply with these policies. Please review them carefully before proceeding.",
    slides: [
      {
        sections: [
          {
            title:
              'Sprin Technologies Limited ("Sprin," "we," "us," "our") offers a platform for users to order food from nearby restaurants and have it delivered to their doorsteps (“Services”). Transparency is a fundamental value at Sprin, and we are committed to clearly explaining how we handle your personal information. Personal information includes unique data or aggregated information that can identify you as an individual when processed with other details. We encourage you to read this privacy notice ("Notice") carefully to understand your rights and our obligations regarding your personal data, alongside our Terms of Use. If you have any questions about either document, please email us at sprinapp@gmail.com.',
            number: "1.",
            texts: [],
          },
          {
            title:
              "Document version control: This Notice is subject to change and may be updated due to legislative changes, business needs, or alterations in our operational practices. Any modifications to this Notice will be approved by the authorized officers.",
            number: "2.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Personal data",
    description: "Everything you should understand about personal information",
    slides: [
      {
        sections: [
          {
            title: "Purpose of Collecting Your Personal Information",
            number: "1.",
            texts: [
              "Deliver Sprin Services and manage the restaurant listings in your area.",
              "Notify you about various product options.",
              "Fulfill our legal and regulatory responsibilities.",
              "Innovate, refine, and expand our services.",
              "Confirm your identity, ensure the accuracy of the data, and prevent fraudulent activities.",
              "Offer customer support to you.",
              "Detect and prevent fraud for your safety.",
              "Use it for any other purpose with your consent.",
            ],
          },
          {
            title: "Consent",
            number: "2.",
            texts: [
              "You accept this privacy notice by clicking the “accept” button to opt-in and use our platforms, services, features, technologies, or functions offered on our website and digital platforms, or by visiting any of our offices for official or non-official purposes. If you decide to opt out, you can do so by contacting us at sprinapp@gmail.com. This privacy notice governs the use of Sprin Services, and if you are not comfortable with us processing your data, you may choose not to use our Services.",
            ],
          },
          {
            title: "How We Collect Your Personal Information",
            number: "3.",
            texts: [
              "We gather information directly from you and automatically through your use of our Services, and occasionally from third parties with your authorization.",
              "When you fill out a form, participate in a contest or promotion, request customer service, or otherwise communicate with us, we collect your information to better understand your needs and provide relevant services to you.",
              "Additionally, we may receive information about you from other sources and combine it with the data we collect directly. Examples of such information from other sources include updated delivery or payment details to maintain accurate records, purchase or redemption information, as well as customer support and enrollment information.",
            ],
          },
          {
            title:
              "What Personal Information Do We Collect About You: We only collect essential information for you to create an account with Sprin and utilize our Services.",
            number: "4.",
            texts: [
              "Your personal details related to your account, such as your name, age, gender, address, location, email address(es), and mobile contact number. Additionally, we may collect the name, location, phone number, and other information about third parties related to you or their use of our services if provided to us.",
              "Details concerning when and where you request our Services and the specifics of any orders placed or received by you concerning our Services, including your geographical location.",
              "Information about the device on which any of our Platforms or applications have been installed or accessed, which may include your operating system and versions, unique device identifiers, serial numbers, mobile network, and Wi-Fi information.",
              "Personal information obtained and retained through financial or third-party payment systems about the payment mechanism or method you might use (including credit card details) and the payments you make for our services or other goods or services you request or receive related to the services we provide.",
              "Your connections to other individuals whose personal information we may collect or retain, such as family members, friends, or referrals.",
              "Details about your participation in any loyalty programs linked or available through us or any of our business partners or affiliates.",
              "If you use social media to interact with us or other services provided by third parties through Sprin, any information that you permit the social media platform and/or the other third-party site to share with us.",
              "Any address you may utilize for delivery services.",
              "If you respond to optional survey requests, demographic information including age, marital status, ethnicity, profession, and income.",
            ],
          },
          {
            title:
              "How We Use Your Personal Information: The purpose of collecting your personal information is to provide you with efficient, enjoyable, and secure Service.",

            number: "5.",
            texts: [
              "Provide our Services to you.",
              "Communicate with you.",
              "Notify you of any important information or content that you may be interested in.",
              "Conduct research and development.",
              "Ensure and enhance the safety and security of Users and relevant third parties.",
              "Provide customer support services such as facilitating responses to your inquiries or complaints.",
              "Conduct customer surveys and research activities.",
              "Conduct marketing, promotions, and advertising activities.",
              "Fulfill our obligations or claim our rights in legal proceedings.",
              "Facilitate communications between Users.",
              "Authenticate Users.",
              "Investigate and prevent possible fraud and illegal activity.",
              "Process and facilitate payment for services facilitated by us.",
              "Deal with disputes and complaints.",
              "Comply with laws, regulations, and rules, including assisting government agencies and law enforcement investigations.",
              "Report to regulatory authorities or government bodies on regulatory compliance.",
              "Use or disclose it as otherwise authorized or permitted by law.",
              "Contact you at any time through your provided telephone number, email address, or other contact details.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Information Regarding Your Personal Data",
    description:
      "This section outlines the procedures, rights, and considerations associated with the management and handling of individuals' personal data by the service provider.",
    slides: [
      {
        sections: [
          {
            title:
              "Your Rights: You have the right to make the following requests regarding your personal information within our custody.",
            number: "1.",
            texts: [
              "Confirmation of the existence and use of personal information about you.",
              "Access to the personal information we hold about you, provided such requests comply with relevant enactments.",
              "Correction or updates to the personal information we hold about you.",
              "Anonymization, blocking, or erasure of your personal information that is no longer necessary for our services or normal business operations.",
              "Data portability, allowing you to request a structured, machine-readable copy of your personal information to a third-party service provider.",
              "Deletion or erasure of personal information we hold about you, where applicable legal or regulatory obligations permit.",
              "Information regarding third parties to whom your personal information is disclosed, not covered in other sections.",
              "Revocation of your consent, where applicable.",
            ],
          },
          {
            title:
              "Making Requests: You can make any of the above requests by contacting us. We may require you to complete a form and provide suitable identification to verify your identity.",
            number: "2.",
            texts: [],
          },
          {
            title:
              "Response Time: We aim to respond promptly. Simple requests will be addressed within 2-3 days, while complex ones may take up to 20 working days. If unable to comply for any reason, we will inform you.",
            number: "3.",
            texts: [],
          },
          {
            title:
              "Confidentiality: If the information provided in response to your request includes third-party personal information or confidential data, we may redact such information as necessary. You must keep this information confidential and refrain from sharing it without our written consent.",
            number: "4.",
            texts: [],
          },
          {
            title:
              "Account Deletion: Users can delete their Chowdeck account. Upon deletion, we will retain personal information in accordance with our record-keeping policies and obligations.",
            number: "5.",
            texts: [],
          },
          {
            title:
              "Retention Policy: We may retain your personal information if:",
            number: "6.",
            texts: [
              "There are unresolved issues relating to your account.",
              "We are required by applicable law.",
              "It is necessary for legitimate business interests, such as fraud prevention.",
            ],
          },
          {
            title:
              "Data Disposal: When personal information is no longer required, we will take reasonable steps to delete it from our systems or de-identify it.",
            number: "3.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Disclosures of Your Personal Information",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin will not sell or rent your information to third parties. However, we may disclose your information:",
            number: "1.",
            texts: [
              "For any of the reasons outlined in section 7 above and related purposes which would be reasonably expected by you.",
              "To related bodies corporate, business partners, and service providers or vendors we engage, such as customer service providers, to help us provide our Services or conduct data processing on our behalf. These entities may be located and operate overseas, except where an exception under the Nigerian Data Protection Regulation Legislation applies.",
              "Subject to any limitations in your information permissions and settings, we may share your information for our everyday business purposes, such as processing your orders and delivery purposes.",
              "With your consent and direction or as otherwise required by law.",
            ],
          },
          {
            title:
              "Sprin will comply with its obligations under the relevant privacy law in relation to any such disclosures, including using reasonable commercial efforts to ensure that such third parties only use your personal information as reasonably required for the purpose of disclosure and in a manner consistent with the privacy legislation. For example, (where commercially practicable) by including suitable privacy and confidentiality clauses in our agreements with those third parties.",
            number: "2.",
            texts: [],
          },
          {
            title:
              "Your privacy and information permissions and settings can be updated at any time on the Sprin mobile application when you are logged into your account.",
            number: "3.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Third-Party Technologies",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "At times, we may choose to integrate or offer third-party products or services on our Platform. These third-party sites operate under separate and independent privacy policies. Consequently, we hold no responsibility or liability for the content and activities of these linked sites. However, we are committed to safeguarding the integrity of our Platform and welcome any feedback regarding these sites.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Location Services",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Upon your initial use of our Platform, we will request your permission to access your location. Location services are essential for us to deliver our Services effectively. You have the flexibility to adjust these settings on your device at any time. With your consent, we gather your location through various means such as IP address, GPS, and other sensors necessary for Service provision. Additionally, we may collect Wi-Fi metadata from your device to recommend nearby restaurants, including Wi-Fi and GPS status, MAC address, SSID, signal levels, and GPS location relative to Wi-Fi networks.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Communication",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "The Sprin Platform allows anonymous communication via call or message once food has been purchased for delivery. We record all communications (voice and text) for safety, monitoring, quality assurance, fraud prevention, and analytical purposes to enhance our services and investigate disputes or complaints. Your consent to recording and using these communications is implied by using this functionality, as outlined in this Privacy Notice.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Your Social Media Use",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin utilizes third-party platforms like Facebook and Twitter for interactive information sharing and user connectivity. We advise you to review the terms and conditions, including privacy policies, of any third-party web service concerning the handling of your personal information. It's important to maintain the confidentiality of your personal information when interacting with them through direct or private messages, email, or customer service hotlines.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Surveys",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We may invite you to participate in surveys to gain insights into our Services and enhance them. Your participation may involve providing demographic information such as age, marital status, ethnicity, profession, and income, which could include sensitive data. Additionally, we may collect information about your usage patterns and experiences with our Services. By participating in surveys, you consent to our collection and utilization of the provided information in accordance with this privacy notice.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Disclaimer",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin has no prior relationship or representation with the restaurants whose directory services are listed on Sprin’s Platform. Sprin is solely responsible for the purchase of the customer's order and the delivery of the order to the customer's provided address upon confirmation of payment.",
            number: "1.",
            texts: [],
          },
          {
            title:
              "Sprin will not be responsible for any breach in the representations made by the restaurant concerning any order made by you and does not guarantee that the representations will be true and accurate.",
            number: "2.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Storage and Security",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, and unauthorized access. Personal information we collect is stored on servers in electronic databases managed by our related entities and/or service providers.",
            number: "1.",
            texts: [],
          },
          {
            title:
              "We have implemented suitable physical, electronic, and administrative procedures and measures to safeguard and protect the information we collect about you, and to reduce the risks of loss, misuse, disclosure, alteration, and unauthorized access to your personal information within our custody. Personal information and associated data stored on servers is encrypted and stored through data isolation technology. We limit access to personal information to individuals within our employ, our related entities, or contracted service providers who we believe reasonably need access to such information to provide products or services to you, to us, or to perform their duties. Some of the safeguards we use include firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.",
            number: "2.",
            texts: [],
          },
          {
            title:
              'To prevent unauthorized access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. Our website uses Secure Sockets Layer (SSL) to ensure secure transmission of your personal data. You should see the padlock symbol in your URL address bar when browsing through our website. The URL address will also start with "https://," depicting a secure webpage. SSL applies encryption between two points, such as your PC and the connecting server. Any data transmitted during the session will be encrypted before transmission and decrypted at the receiving end, ensuring that data cannot be read during transmission.',
            number: "2.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Retention Period",
    description:
      "Subject to the provisions of the NDPR 2019, the retention period of personal information of users of Sprin shall be as follows:",
    slides: [
      {
        sections: [
          {
            title:
              "Five (5) years after the last active use of our digital platform.",
            number: "1.",
            texts: [],
          },
          {
            title:
              "Upon presentation of evidence of death by a deceased’s relative, the personal information of such user will be discarded.",
            number: "2.",
            texts: [],
          },
          {
            title:
              "Immediately upon request by the user of Sprin or their legal guardian, provided that:",
            number: "3.",
            texts: [
              "No statutory provision states otherwise and the user is not the subject of an investigation or suit that may require retention of the personal information sought to be deleted.",
            ],
          },
        ],
      },
    ],
  },
];

const privacy = [
  {
    title: "Who we are & our policy",
    description:
      "By accessing and using our website or app, you agree to comply with these policies. Please review them carefully before proceeding.",
    slides: [
      {
        sections: [
          {
            title:
              'Sprin Technologies Limited ("Sprin," "we," "us," "our") offers a platform for users to order food from nearby restaurants and have it delivered to their doorsteps (“Services”). Transparency is a fundamental value at Sprin, and we are committed to clearly explaining how we handle your personal information. Personal information includes unique data or aggregated information that can identify you as an individual when processed with other details. We encourage you to read this privacy notice ("Notice") carefully to understand your rights and our obligations regarding your personal data, alongside our Terms of Use. If you have any questions about either document, please email us at sprinapp@gmail.com.',
            number: "1.",
            texts: [],
          },
          {
            title:
              "Document version control: This Notice is subject to change and may be updated due to legislative changes, business needs, or alterations in our operational practices. Any modifications to this Notice will be approved by the authorized officers.",
            number: "2.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Personal data",
    description: "Everything you should understand about personal information",
    slides: [
      {
        sections: [
          {
            title: "Purpose of Collecting Your Personal Information",
            number: "1.",
            texts: [
              "Deliver Sprin Services and manage the restaurant listings in your area.",
              "Notify you about various product options.",
              "Fulfill our legal and regulatory responsibilities.",
              "Innovate, refine, and expand our services.",
              "Confirm your identity, ensure the accuracy of the data, and prevent fraudulent activities.",
              "Offer customer support to you.",
              "Detect and prevent fraud for your safety.",
              "Use it for any other purpose with your consent.",
            ],
          },
          {
            title: "Consent",
            number: "2.",
            texts: [
              "You accept this privacy notice by clicking the “accept” button to opt-in and use our platforms, services, features, technologies, or functions offered on our website and digital platforms, or by visiting any of our offices for official or non-official purposes. If you decide to opt out, you can do so by contacting us at sprinapp@gmail.com. This privacy notice governs the use of Sprin Services, and if you are not comfortable with us processing your data, you may choose not to use our Services.",
            ],
          },
          {
            title: "How We Collect Your Personal Information",
            number: "3.",
            texts: [
              "We gather information directly from you and automatically through your use of our Services, and occasionally from third parties with your authorization.",
              "When you fill out a form, participate in a contest or promotion, request customer service, or otherwise communicate with us, we collect your information to better understand your needs and provide relevant services to you.",
              "Additionally, we may receive information about you from other sources and combine it with the data we collect directly. Examples of such information from other sources include updated delivery or payment details to maintain accurate records, purchase or redemption information, as well as customer support and enrollment information.",
            ],
          },
          {
            title:
              "What Personal Information Do We Collect About You: We only collect essential information for you to create an account with Sprin and utilize our Services.",
            number: "4.",
            texts: [
              "Your personal details related to your account, such as your name, age, gender, address, location, email address(es), and mobile contact number. Additionally, we may collect the name, location, phone number, and other information about third parties related to you or their use of our services if provided to us.",
              "Details concerning when and where you request our Services and the specifics of any orders placed or received by you concerning our Services, including your geographical location.",
              "Information about the device on which any of our Platforms or applications have been installed or accessed, which may include your operating system and versions, unique device identifiers, serial numbers, mobile network, and Wi-Fi information.",
              "Personal information obtained and retained through financial or third-party payment systems about the payment mechanism or method you might use (including credit card details) and the payments you make for our services or other goods or services you request or receive related to the services we provide.",
              "Your connections to other individuals whose personal information we may collect or retain, such as family members, friends, or referrals.",
              "Details about your participation in any loyalty programs linked or available through us or any of our business partners or affiliates.",
              "If you use social media to interact with us or other services provided by third parties through Sprin, any information that you permit the social media platform and/or the other third-party site to share with us.",
              "Any address you may utilize for delivery services.",
              "If you respond to optional survey requests, demographic information including age, marital status, ethnicity, profession, and income.",
            ],
          },
          {
            title:
              "How We Use Your Personal Information: The purpose of collecting your personal information is to provide you with efficient, enjoyable, and secure Service.",

            number: "5.",
            texts: [
              "Provide our Services to you.",
              "Communicate with you.",
              "Notify you of any important information or content that you may be interested in.",
              "Conduct research and development.",
              "Ensure and enhance the safety and security of Users and relevant third parties.",
              "Provide customer support services such as facilitating responses to your inquiries or complaints.",
              "Conduct customer surveys and research activities.",
              "Conduct marketing, promotions, and advertising activities.",
              "Fulfill our obligations or claim our rights in legal proceedings.",
              "Facilitate communications between Users.",
              "Authenticate Users.",
              "Investigate and prevent possible fraud and illegal activity.",
              "Process and facilitate payment for services facilitated by us.",
              "Deal with disputes and complaints.",
              "Comply with laws, regulations, and rules, including assisting government agencies and law enforcement investigations.",
              "Report to regulatory authorities or government bodies on regulatory compliance.",
              "Use or disclose it as otherwise authorized or permitted by law.",
              "Contact you at any time through your provided telephone number, email address, or other contact details.",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Information Regarding Your Personal Data",
    description:
      "This section outlines the procedures, rights, and considerations associated with the management and handling of individuals' personal data by the service provider.",
    slides: [
      {
        sections: [
          {
            title:
              "Your Rights: You have the right to make the following requests regarding your personal information within our custody.",
            number: "1.",
            texts: [
              "Confirmation of the existence and use of personal information about you.",
              "Access to the personal information we hold about you, provided such requests comply with relevant enactments.",
              "Correction or updates to the personal information we hold about you.",
              "Anonymization, blocking, or erasure of your personal information that is no longer necessary for our services or normal business operations.",
              "Data portability, allowing you to request a structured, machine-readable copy of your personal information to a third-party service provider.",
              "Deletion or erasure of personal information we hold about you, where applicable legal or regulatory obligations permit.",
              "Information regarding third parties to whom your personal information is disclosed, not covered in other sections.",
              "Revocation of your consent, where applicable.",
            ],
          },
          {
            title:
              "Making Requests: You can make any of the above requests by contacting us. We may require you to complete a form and provide suitable identification to verify your identity.",
            number: "2.",
            texts: [],
          },
          {
            title:
              "Response Time: We aim to respond promptly. Simple requests will be addressed within 2-3 days, while complex ones may take up to 20 working days. If unable to comply for any reason, we will inform you.",
            number: "3.",
            texts: [],
          },
          {
            title:
              "Confidentiality: If the information provided in response to your request includes third-party personal information or confidential data, we may redact such information as necessary. You must keep this information confidential and refrain from sharing it without our written consent.",
            number: "4.",
            texts: [],
          },
          {
            title:
              "Account Deletion: Users can delete their Chowdeck account. Upon deletion, we will retain personal information in accordance with our record-keeping policies and obligations.",
            number: "5.",
            texts: [],
          },
          {
            title:
              "Retention Policy: We may retain your personal information if:",
            number: "6.",
            texts: [
              "There are unresolved issues relating to your account.",
              "We are required by applicable law.",
              "It is necessary for legitimate business interests, such as fraud prevention.",
            ],
          },
          {
            title:
              "Data Disposal: When personal information is no longer required, we will take reasonable steps to delete it from our systems or de-identify it.",
            number: "3.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Disclosures of Your Personal Information",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin will not sell or rent your information to third parties. However, we may disclose your information:",
            number: "1.",
            texts: [
              "For any of the reasons outlined in section 7 above and related purposes which would be reasonably expected by you.",
              "To related bodies corporate, business partners, and service providers or vendors we engage, such as customer service providers, to help us provide our Services or conduct data processing on our behalf. These entities may be located and operate overseas, except where an exception under the Nigerian Data Protection Regulation Legislation applies.",
              "Subject to any limitations in your information permissions and settings, we may share your information for our everyday business purposes, such as processing your orders and delivery purposes.",
              "With your consent and direction or as otherwise required by law.",
            ],
          },
          {
            title:
              "Sprin will comply with its obligations under the relevant privacy law in relation to any such disclosures, including using reasonable commercial efforts to ensure that such third parties only use your personal information as reasonably required for the purpose of disclosure and in a manner consistent with the privacy legislation. For example, (where commercially practicable) by including suitable privacy and confidentiality clauses in our agreements with those third parties.",
            number: "2.",
            texts: [],
          },
          {
            title:
              "Your privacy and information permissions and settings can be updated at any time on the Sprin mobile application when you are logged into your account.",
            number: "3.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Third-Party Technologies",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "At times, we may choose to integrate or offer third-party products or services on our Platform. These third-party sites operate under separate and independent privacy policies. Consequently, we hold no responsibility or liability for the content and activities of these linked sites. However, we are committed to safeguarding the integrity of our Platform and welcome any feedback regarding these sites.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Location Services",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Upon your initial use of our Platform, we will request your permission to access your location. Location services are essential for us to deliver our Services effectively. You have the flexibility to adjust these settings on your device at any time. With your consent, we gather your location through various means such as IP address, GPS, and other sensors necessary for Service provision. Additionally, we may collect Wi-Fi metadata from your device to recommend nearby restaurants, including Wi-Fi and GPS status, MAC address, SSID, signal levels, and GPS location relative to Wi-Fi networks.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Communication",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "The Sprin Platform allows anonymous communication via call or message once food has been purchased for delivery. We record all communications (voice and text) for safety, monitoring, quality assurance, fraud prevention, and analytical purposes to enhance our services and investigate disputes or complaints. Your consent to recording and using these communications is implied by using this functionality, as outlined in this Privacy Notice.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Your Social Media Use",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin utilizes third-party platforms like Facebook and Twitter for interactive information sharing and user connectivity. We advise you to review the terms and conditions, including privacy policies, of any third-party web service concerning the handling of your personal information. It's important to maintain the confidentiality of your personal information when interacting with them through direct or private messages, email, or customer service hotlines.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Surveys",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We may invite you to participate in surveys to gain insights into our Services and enhance them. Your participation may involve providing demographic information such as age, marital status, ethnicity, profession, and income, which could include sensitive data. Additionally, we may collect information about your usage patterns and experiences with our Services. By participating in surveys, you consent to our collection and utilization of the provided information in accordance with this privacy notice.",
            number: "1.",
            texts: [],
          },
        ],
      },
    ],
  },

  {
    title: "Disclaimer",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "Sprin has no prior relationship or representation with the restaurants whose directory services are listed on Sprin’s Platform. Sprin is solely responsible for the purchase of the customer's order and the delivery of the order to the customer's provided address upon confirmation of payment.",
            number: "1.",
            texts: [],
          },
          {
            title:
              "Sprin will not be responsible for any breach in the representations made by the restaurant concerning any order made by you and does not guarantee that the representations will be true and accurate.",
            number: "2.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Storage and Security",
    description: "",
    slides: [
      {
        sections: [
          {
            title:
              "We protect your information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, and unauthorized access. Personal information we collect is stored on servers in electronic databases managed by our related entities and/or service providers.",
            number: "1.",
            texts: [],
          },
          {
            title:
              "We have implemented suitable physical, electronic, and administrative procedures and measures to safeguard and protect the information we collect about you, and to reduce the risks of loss, misuse, disclosure, alteration, and unauthorized access to your personal information within our custody. Personal information and associated data stored on servers is encrypted and stored through data isolation technology. We limit access to personal information to individuals within our employ, our related entities, or contracted service providers who we believe reasonably need access to such information to provide products or services to you, to us, or to perform their duties. Some of the safeguards we use include firewalls and data encryption, physical access controls to our data centers, and information access authorization controls.",
            number: "2.",
            texts: [],
          },
          {
            title:
              'To prevent unauthorized access to your information, we have implemented strong controls and security safeguards at the technical and operational levels. Our website uses Secure Sockets Layer (SSL) to ensure secure transmission of your personal data. You should see the padlock symbol in your URL address bar when browsing through our website. The URL address will also start with "https://," depicting a secure webpage. SSL applies encryption between two points, such as your PC and the connecting server. Any data transmitted during the session will be encrypted before transmission and decrypted at the receiving end, ensuring that data cannot be read during transmission.',
            number: "2.",
            texts: [],
          },
        ],
      },
    ],
  },
  {
    title: "Retention Period",
    description:
      "Subject to the provisions of the NDPR 2019, the retention period of personal information of users of Sprin shall be as follows:",
    slides: [
      {
        sections: [
          {
            title:
              "Five (5) years after the last active use of our digital platform.",
            number: "1.",
            texts: [],
          },
          {
            title:
              "Upon presentation of evidence of death by a deceased’s relative, the personal information of such user will be discarded.",
            number: "2.",
            texts: [],
          },
          {
            title:
              "Immediately upon request by the user of Sprin or their legal guardian, provided that:",
            number: "3.",
            texts: [
              "No statutory provision states otherwise and the user is not the subject of an investigation or suit that may require retention of the personal information sought to be deleted.",
            ],
          },
        ],
      },
    ],
  },
];

export { faqs, privacy };
